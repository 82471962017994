import { BarcodeViz } from "../../features/barcode";
import { useGetScriptDataQuery } from "../../services/pranaApi";
import Spinner from "../../utils/spinner";

function PosterBarcode({ concertDate, token }) {
  const kindValue = "rr";
  const levelValue = "segments";
  const { data, isLoading } = useGetScriptDataQuery(concertDate);
  if (isLoading) return <Spinner />;
  return (
    <BarcodeViz
      animate={false}
      data={data}
      highlighted={token}
      isBw={false}
      isLoading={isLoading}
      kind={kindValue}
      level={levelValue}
    />
  );
}

function PosterHeaderToken({ concertDate, token }) {
  const dateDate = new Date(concertDate);
  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };
  const displayDate = new Intl.DateTimeFormat("de", dateOptions).format(
    dateDate
  );
  return (
    <>
      <div>
        <h3 className="pb-0">
          Besucher <span className=" text-uppercase">{token}</span>
        </h3>
      </div>
      <div className="mt-2">
        <div>{displayDate}</div>
      </div>
    </>
  );
}

function PosterHeaderTitle() {
  return (
    <>
      <div>
        <h1 className="mb-2 pb-0">प्राण prāna</h1>
      </div>
      <div className="fst-italic mt-1">Chandrasekhar Ramakrishnan</div>
    </>
  );
}

function PosterHeaderEcr() {
  return (
    <>
      <div className="text-end">
        <div>Experimental</div>
      </div>
      <div className="text-end text-muted mt-1">
        <div>Concert</div>
      </div>
      <div className="text-end mt-1">
        <div>Research</div>
      </div>
    </>
  );
}

function PosterHeader({ concertDate, token }) {
  return (
    <div className="d-flex flex-row justify-content-between mt-3">
      <div className="flex-grow-1 d-flex flex-column">
        <PosterHeaderToken concertDate={concertDate} token={token} />
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <PosterHeaderTitle />
      </div>
      <div className="d-flex flex-column">
        <PosterHeaderEcr />
      </div>
    </div>
  );
}

function Poster({ concertDate, token }) {
  return (
    <div className="mt-5">
      <PosterBarcode concertDate={concertDate} token={token} />
      <PosterHeader concertDate={concertDate} token={token} />
    </div>
  );
}

export default Poster;
