import React from "react";
/* eslint-disable spellcheck/spell-checker */

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import beschreibung from "./beschreibung.svg";

function MiniRectangle({ size }) {
  if (size == null) size = 2;
  const width = 10;
  const height = 20;
  const start = (width - size) / 2;
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      style={{ backgroundColor: "white" }}>
      <rect x={start} y={2} width={size} height={height - 4} fill="#C0C0C0" />
    </svg>
  );
}

function PosterExplanation() {
  return (
    <>
      <Row className="mt-5">
        <Col md={12}>
          <h1>प्राण prāna</h1>
          <div className="serif">
            Diese Arbeit von <b>Chandrasekhar Ramakrishnan</b> heißt{" "}
            <i>prāna</i>, und das Bild, das Sie auf Ihrem Ausdruck sehen werden,
            ist eine Art <i>Partitur Ihres Konzerterlebnisses</i>.
          </div>
          <div className="mt-2 serif">
            Diese Partitur wurde nur für Sie und niemanden sonst erstellt. Sie
            können sie ausdrucken und als Erinnerung an dieses Konzert
            mitnehmen. Wenn Sie wissen möchten, was das Bild zeigt lesen Sie
            weiter.
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={{ offset: 2, span: 8 }}>
          <img
            className="pt-3 bg-white"
            src={beschreibung}
            alt="Beschreibung des Posters"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <div className="serif">
            Unten Links finden Sie den Token, der Ihre Person identifiziert,
            darunter das Datum des Konzertes, das Sie besuchten. Die Darstellung
            vom Ablauf Ihres Konzertbesuches finden Sie darüber.
          </div>
          <div className="mt-2 serif">
            Im Konzert haben Sie drei Stücke gehört, jeweils eines von Ludwig
            van Beethoven, Brett Dean und Johannes Brahms. Jedes Stück wurde in
            mehrere musikalisch bedeutsame Momente unterteilt, und jeder dieser
            Momente wird als Rechteck dargestellt. Manche Rechtecke sind so
            klein, dass sie als Striche erscheinen, andere sind breiter. Die
            Breite des Rechtecks entspricht der{" "}
            <i>Unregelmäßigkeit Ihrer Atmung</i> in diesem Moment:
          </div>
          <div className="mt-2 serif">
            Regelmäßige Atmung ergibt einen schmalen Strich{" "}
            <MiniRectangle size={1} />, unregelmäßige Atmung ein breites
            Rechteck <MiniRectangle size={8} />. Es gibt viele mögliche Gründe,
            warum die Atmung in einem Moment unregelmäßig und in einem anderen
            regelmäßig ist. Beispielsweise kann ein Hustenreiz zu einer
            unregelmäßigen Atmung führen. Auch emotionale Reaktionen auf die
            Konzertsituation, die Musik oder die Inszenierung, könnte eine
            Erklärung sein. Erinnern Sie sich an bestimmte Momente des Konzerts?
            Vielleicht erkennen Sie einen Zusammenhang zwischen die
            Regelmäßigkeit Ihrer Atmung und dem, was Sie im Konzert erlebten.
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <h1>प्राण prāna</h1>
          <div className="serif">
            This piece by <b>Chandrasekhar Ramakrishnan</b> is called{" "}
            <i>prāna</i>, and the image that you will see on your poster is a
            kind of <i>score of your concert experience</i>.
          </div>
          <div className="mt-2 serif">
            This score has been created for you and no one else. You can print
            it out, take it with you and keep it as a remembrance of this
            concert.
          </div>
          <div className="mt-2 serif">
            If you would like details about what the image on the poster
            portrays, continue reading.
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={{ offset: 2, span: 8 }}>
          <img
            className="pt-3 bg-white"
            src={beschreibung}
            alt="Beschreibung des Posters"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <div className="serif">
            On the bottom left, you will see the token that identifies you, and
            directly below is the date of the concert you attended. The main
            space contains a visualization of your concert experience.
          </div>
          <div className="mt-2 serif">
            During the concert, three pieces were performed: a piece each by
            Ludwig Beethoven, Brett Dean, and Johannes Brahms. Within these
            pieces, multiple musically relevant moments were identified, and
            these are displayed here as rectangles. Some of the rectangles are
            so narrow that they appear as lines, while others are wider. The
            width of each rectangle corresponds to the{" "}
            <i>irregularity of your breathing rhythm</i> during that moment of
            the piece.
          </div>
          <div className="mt-2 serif">
            A regular rhythm yields a narrow rectangle{" "}
            <MiniRectangle size={1} />; an irregular rhythm is visible as a wide
            rectangle <MiniRectangle size={8} />. There are many reasons why
            your breath might be irregular in one moment and regular in another.
            For example, a dry throat that provokes a cough can cause an
            irregular breathing rhythm.
          </div>
          <div className="mt-2 serif">
            Emotional reactions to the music or the scenography of the concert
            can also be a cause of changes in the regularity of your breath. Do
            certain moments of the concert remain vivid in your memory? Perhaps
            you can recognize a correspondence between the regularity of your
            breath and what you experienced in the concert.
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PosterExplanation;
