import { useSearchParams } from "react-router-dom";

import Poster from "./poster";
import PosterSelector from "./poster-selector";
import PosterExplanation from "./poster-explanation";

function posterSearchParams(searchParams) {
  const [date, token] = [searchParams.get("date"), searchParams.get("token")];
  if (date != null && token != null) return { date, token };
  return null;
}

function PosterSwitch() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const params = posterSearchParams(searchParams);
  if (params != null)
    return <Poster concertDate={params.date} token={params.token} />;

  return <PosterSelector />;
}

export default PosterSwitch;

export { PosterExplanation, posterSearchParams };
