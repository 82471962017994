/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
/* eslint-disable spellcheck/spell-checker */
import { useSearchParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  useGetOverviewDataQuery,
  useTriggerFetchDataMutation
} from "../../services/pranaApi";
import Spinner from "../../utils/spinner";

function matchForInput(overviewData, tokenInput) {
  const tokens = overviewData.tokens;
  const matchingTokens = tokens.filter((t) => t.token === tokenInput);
  return matchingTokens.length < 1 ? null : matchingTokens[0];
}

function hrefForInput(t) {
  const { concertDate, token } = t;
  return `?date=${concertDate}&token=${token}`;
}

function TokenFinder({
  fetchDataMutation,
  onNotFound,
  onTokenSelected,
  overviewDataQuery,
  searchAttempted,
  selectedToken
}) {
  const { data: overviewData, error, isFetching } = overviewDataQuery;
  const { isLoading: isUpdating } = fetchDataMutation;

  const onValidateToken = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      onTokenSelected(null);
      return;
    }

    const tokenInput = e.target.token.value.trim().toLowerCase();
    const match = matchForInput(overviewData, tokenInput);
    if (match == null) onNotFound(tokenInput);
    else onTokenSelected(match);
  };

  if (isFetching || isUpdating) return <Spinner />;
  if (searchAttempted && selectedToken) return null;

  if (error) {
    return (
      <div>
        Derzeit keine Verbindung zum Server. Poster können nicht ausgedruckt
        werden.
      </div>
    );
  }

  return (
    <Form onSubmit={onValidateToken}>
      <Form.Group className="mb-3" controlId="token">
        <Form.Label>Besuchertoken</Form.Label>
        <Form.Control
          type="text"
          placeholder="coxcq"
          aria-describedby="besucher-token"
          autocomplete="off"
          required
        />
        <Form.Text id="besucher-token" muted>
          Bitte geben Sie Ihren Besuchertoken ein
        </Form.Text>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button variant="primary" type="submit">
          Suchen
        </Button>
      </div>
    </Form>
  );
}

function lastConcertWithData(overviewDataQuery) {
  const concertDates = {};
  overviewDataQuery.data.tokens.forEach(
    (t) => (concertDates[t.concertDate] = true)
  );
  const sortedConcertDates = Object.keys(concertDates);
  sortedConcertDates.sort();
  return sortedConcertDates[sortedConcertDates.length - 1];
}

function hasDataForToday(lastConcert, today) {
  const formatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  let [month, day, year] = formatter.format(today).split("/");
  const todayString = `${year}-${month}-${day}`;
  return lastConcert === todayString;
}

function TokenNotFound({ overviewDataQuery, tokenCandidate }) {
  const lastConcertDate = lastConcertWithData(overviewDataQuery);
  const today = new Date();
  const selectedToken = {
    concertDate: lastConcertDate,
    token: tokenCandidate
  };
  const message = hasDataForToday(lastConcertDate, today)
    ? "Sie können Ihre Eingabe überprüfen und es erneut versuchen oder trotzdem ein Poster ausdrucken."
    : "Die Daten des heutigen Konzerts sind noch nicht verfügbar. Es sollte nicht mehr lange dauern, aber falls Sie nicht warten wollen, können Sie trotzdem ein Poster ausdrucken.";

  return (
    <div>
      <div>
        Der Token <span className="fw-bold fst-italic">{tokenCandidate}</span>{" "}
        konnte nicht gefunden werden.
      </div>
      <div className="text-white-50">{message}</div>
      <Button
        href={hrefForInput(selectedToken)}
        target="_blank"
        rel="noopener"
        className="me-1"
        variant="secondary">
        Zum Poster
      </Button>
    </div>
  );
}

function TokenFound({ onClear, onPrintPoster, selectedToken }) {
  const { concertDate, token } = selectedToken;
  const onSubmit = (e) => {
    e.preventDefault();
    onPrintPoster(selectedToken);
  };
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="token-gefunden">
        <Form.Label>
          Token gefunden
          {"  " /* eslint-disable max-len */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="green"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          {/* eslint-enable max-len */}
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3" controlId="token-info">
        <Form.Text id="token-info">
          {token} hat das konzert am {concertDate} besucht
        </Form.Text>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button className="me-1" variant="secondary" onClick={onClear}>
          Neu Anfangen
        </Button>
        <Button
          href={hrefForInput(selectedToken)}
          target="_blank"
          rel="noopener"
          variant="primary">
          Zum Poster
        </Button>
      </div>
    </Form>
  );
}

function TokenDisplay({
  onClear,
  onPrintPoster,
  overviewDataQuery,
  selectedToken,
  searchAttempted,
  tokenCandidate
}) {
  if (searchAttempted === false) return null;
  if (selectedToken == null) {
    return (
      <TokenNotFound
        onPrintPoster={onPrintPoster}
        overviewDataQuery={overviewDataQuery}
        tokenCandidate={tokenCandidate}
      />
    );
  }

  return (
    <TokenFound
      onClear={onClear}
      onPrintPoster={onPrintPoster}
      selectedToken={selectedToken}
    />
  );
}

function PosterSelector() {
  const overviewDataQuery = useGetOverviewDataQuery();
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenCandidate, setTokenCandidate] = useState(null);
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [refetchTriggered, setRefetchTriggered] = useState(false);
  const [trigger, fetchDataMutation] = useTriggerFetchDataMutation();
  const [_searchParams, setSearchParams] = useSearchParams();

  const { data: overviewData, isFetching, refetch } = overviewDataQuery;
  const { isLoading: isUpdating } = fetchDataMutation;

  const onNotFound = (t) => {
    setTokenCandidate(t);
    trigger();
  };

  useEffect(() => {
    if (tokenCandidate != null && !isUpdating && !searchAttempted) {
      setRefetchTriggered(true);
      refetch();
    }
    return null;
  }, [isUpdating, refetch, tokenCandidate, searchAttempted]);

  useEffect(() => {
    if (tokenCandidate != null && !isFetching && refetchTriggered) {
      const match = matchForInput(overviewData, tokenCandidate);
      setRefetchTriggered(false);
      setSearchAttempted(true);
      setSelectedToken(match);
    }
    return null;
  }, [isFetching, overviewData, tokenCandidate, refetchTriggered]);

  const onTokenSelected = (t) => {
    setRefetchTriggered(false);
    setSearchAttempted(true);
    setSelectedToken(t);
  };

  const onPrintPoster = (t) => {
    const { concertDate, token } = t;
    const params = {
      date: concertDate,
      token: token
    };
    setSearchParams(params);
  };

  const onClear = (t) => {
    setRefetchTriggered(false);
    setSearchAttempted(false);
    setSelectedToken(null);
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h1>ECR Poster</h1>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TokenFinder
            fetchDataMutation={fetchDataMutation}
            onNotFound={onNotFound}
            onTokenSelected={onTokenSelected}
            overviewDataQuery={overviewDataQuery}
            searchAttempted={searchAttempted}
            selectedToken={selectedToken}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <TokenDisplay
            onClear={onClear}
            onPrintPoster={onPrintPoster}
            overviewDataQuery={overviewDataQuery}
            selectedToken={selectedToken}
            searchAttempted={searchAttempted}
            tokenCandidate={tokenCandidate}
          />
        </Col>
      </Row>
    </>
  );
}

export default PosterSelector;

export { hasDataForToday };
