import React from "react";
/* eslint-disable spellcheck/spell-checker */
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

function Lobby() {
  return (
    <>
      <Row className="mt-5">
        <Col md={6}>
          <h3>प्राण prāna</h3>
        </Col>
      </Row>
      <Row className="mt-5 fs-3">
        <Col md={{ offset: 2, span: 9 }}>
          <div>
            <Link to="/1">
              Zum Fortfahren bitte drücken Sie hier /<br />
              Please click here to continue
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Lobby;
