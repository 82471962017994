const RowReference = {
  CONCERT: "CONCERT",
  SEGMENT: "SEGMENT"
};

function summaryToRect(summary, ref) {
  const result = { start: null, width: null };

  // ref range covers 0 -> 1
  const refRange = ref["90"] - ref["10"];

  if (summary["90"] != null && ref["90"] != null) {
    // start at 0 if summary == ref
    const relStart = (summary["10"] - ref["10"]) / refRange;
    // end at 1 if summary == ref
    const relWidth = (summary["90"] - summary["10"]) / refRange;
    result["start"] = relStart;
    result["width"] = relWidth;
  }

  return result;
}

function segmentToRect(summary, ref, kind) {
  const result = {};

  result["name"] = summary["name"];
  result["segment"] = summary["segment"];
  result["movement"] = summary["movement"];
  result["piece"] = summary["piece"];
  result["ts"] = summary["ts"];

  result[kind] = summaryToRect(summary[kind], ref[kind]);

  return result;
}

function computeReference(row, reference, kind) {
  if (reference === RowReference.CONCERT) return row["concert"][0];
  if (reference === RowReference.SEGMENT) {
    // find the widest possible range
    let max90 = row["segments"][0][kind]["90"];
    let min10 = row["segments"][0][kind]["10"];
    for (const s of row["segments"]) {
      if (s[kind]["90"] > max90) max90 = s[kind]["90"];
      if (s[kind]["10"] < min10) min10 = s[kind]["10"];
    }
    return max90 == null
      ? row["concert"][0]
      : { [kind]: { 90: max90, 10: min10 } };
  }
  return row["concert"][0];
}

function vizDataRowToRect(row, reference, kind) {
  const result = {};
  const ref = computeReference(row, reference, kind);

  result["segments"] = row["segments"].map((d) => segmentToRect(d, ref, kind));
  result["movements"] = row["movements"].map((d) =>
    segmentToRect(d, ref, kind)
  );
  result["pieces"] = row["pieces"].map((d) => segmentToRect(d, ref, kind));
  result["concert"] = row["concert"].map((d) => segmentToRect(d, ref, kind));
  result["peaks"] = row["peaks"];
  result["token"] = row["token"];
  return result;
}

function vizDataToRect(data, reference = RowReference.CONCERT, kind = "rr") {
  return data.map((r) => vizDataRowToRect(r, reference, kind));
}

function sortedRectRows(data) {
  return data;
}

export { RowReference, sortedRectRows, vizDataToRect };

// for testing
export { summaryToRect };
