import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPlayed } from "./sound-slice";

function EcrSound({ current }) {
  const dispatch = useDispatch();
  const ref = useRef();
  const hasPlayed = useSelector((state) => state.sound.hasPlayed);
  const [isPlaying, setPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    const waitTimeS = 2;
    const to = setTimeout(() => {
      if (isPlaying === false) setShowControls(true);
      else setShowControls(false);
      if (!hasPlayed) dispatch(setPlayed(isPlaying));
    }, waitTimeS * 1000);
    return () => clearTimeout(to);
  }, [current, dispatch, hasPlayed, isPlaying]);

  useEffect(() => {
    ref.current.volume = 0.5;
  }, [ref]);

  const onPlay = (e) => {
    setPlaying(true);
  };

  return (
    <audio
      ref={ref}
      onPlay={onPlay}
      autoPlay={true}
      controls={showControls ? true : undefined}
      loop={false}
      src={`/media/ecr${current}.mp4`}
    />
  );
}

export default EcrSound;
