import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
  name: "ecrSound",
  initialState: {
    hasPlayed: false
  },
  reducers: {
    setPlayed(state, action) {
      state.hasPlayed = action.payload;
    }
  }
});

export const { setPlayed } = soundSlice.actions;
export default soundSlice.reducer;
