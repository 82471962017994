import { useGetScriptDataQuery } from "../services/pranaApi";
import Spinner from "../utils/spinner";
import { RowReference, vizDataToRect } from "../utils/viz";

/* eslint-disable */
const boulez1 = {
  settingId: "boulez 1",
  concertDate: "2022-04-12",
  female: {
    uo3ux: true,
    "4oux2": true,
    gbvmf: true,
    szfvb: true,
    sxtpn: true,
    sxm95: true,
    ydjso: true,
    drehz: true,
    cpby8: true,
    acmu2: true,
    z9kbp: true,
    qss4q: true,
    ysvfr: true,
    jstfx: true,
    "2gccp": true,
    tw2qm: true,
    "5az85": true,
    "67qam": true,
    "9s59k": true,
    jbr5p: true,
    ak76f: true,
    nzf9s: true,
    efaqr: true,
    uawvf: true,
    wrbuq: true,
    junat: true,
    n3te3: true,
    fkvhq: true,
    c9z5t: true,
    rfm9x: true,
    ty9ow: true,
    dq2uc: true,
    gzcdf: true,
    o7qdh: true,
    eb9k4: true,
    "9t5aw": true,
    b56t3: true,
    mcxq9: true,
    jtf3e: true,
    ee2kq: true
  },
  male: {
    "5z2zm": true,
    cfy2c: true,
    zf8ua: true,
    mmozq: true,
    avxom: true,
    srxsv: true,
    ssxer: true,
    g8wzm: true,
    zsom5: true,
    xmspj: true,
    qqwba: true,
    "7ufw2": true,
    xxxpp: true,
    phcxq: true,
    mt2mp: true,
    gkcms: true,
    rywtw: true,
    qskbc: true,
    j5xxb: true,
    vo7zc: true,
    d5abb: true,
    bz8r5: true,
    fgxch: true,
    h3fy3: true,
    gsmjp: true,
    eqz55: true,
    vymaq: true
  }
};

const boulez2 = {
  settingId: "boulez 2",
  concertDate: "2022-04-13",
  female: {
    "7agbd": true,
    c9qa9: true,
    ds9cx: true,
    zhyco: true,
    bd7ds: true,
    fhst9: true,
    "4vesh": true,
    "2dbbg": true,
    "9dv9j": true,
    jxvzt: true,
    eswov: true,
    oaa8d: true,
    apqa5: true,
    sdxda: true,
    "6mw2o": true,
    k9bta: true,
    nrxdn: true,
    pkh4b: true,
    "27maq": true,
    ggzwq: true,
    x2vdg: true,
    xx3em: true,
    jszxh: true,
    c3eu3: true,
    xc2bk: true,
    twbnz: true,
    qn3qf: true,
    pnftc: true,
    dphcy: true,
    "6w8gz": true,
    cwx36: true,
    q2prf: true,
    m4a7n: true,
    yf6g9: true,
    bq94u: true
  },
  male: {
    cz2u6: true,
    xhfmf: true,
    fbatz: true,
    oy26g: true,
    frvhb: true,
    "86d9s": true,
    ps2wk: true,
    "298xd": true,
    ycb9c: true,
    mw7bu: true,
    "98cte": true,
    t43y8: true,
    abcpb: true,
    smxu5: true,
    gsdaf: true,
    dodac: true,
    bstbp: true,
    sbzoc: true,
    bf73s: true,
    h7sqk: true,
    dbbah: true,
    xpwe6: true,
    dkxgd: true,
    rb5ku: true,
    dwfzn: true,
    wnyxp: true,
    fceo7: true,
    zmzrh: true,
    egwf5: true,
    xk7xh: true,
    a8pro: true,
    px2h5: true,
    jdvd8: true,
    fk38b: true
  }
};
/* eslint-enable */

const pieceColorMap = {
  beethoven: "#1b9e77",
  dean: "#d95f02",
  brahms: "#7570b3"
};

// function pieceFillColorColor(isHighlighted, piece) {
//   if (!isHighlighted) return "#C0C0C0";
//   const color = pieceColorMap[piece];
//   return color ?? "#000";
// }

function segmentFillColor(segment) {
  const color = pieceColorMap[segment["piece"]];
  return color ?? "#000";
}

function SummaryBarcodeRow({ colWidth, index, kind, row, rowHeight }) {
  return (
    <g transform={`translate(0, ${rowHeight * index})`}>
      {row["segments"].map((r, i) => {
        if (r[kind]["start"] == null) return null;

        const wedgeStart = colWidth * (i + r[kind]["start"]);
        const wedgeWidth = colWidth * r[kind]["width"];

        const fill = segmentFillColor(r);

        return (
          <rect
            key={i}
            fill={fill}
            x={wedgeStart}
            y={0}
            width={wedgeWidth}
            height={rowHeight}></rect>
        );
      })}
    </g>
  );
}

function BarcodeHeader({ colWidth, highlighted, row, rowHeight }) {
  const y = rowHeight * 0.66;
  return (
    <g transform={`translate(0, 0)`}>
      {row["segments"].map((r, i) => {
        const wedgeStart = colWidth * i;
        const fill = segmentFillColor(r);
        if (r["segment"] !== 1 || r["movement"] !== 1) return null;
        return (
          <text className="fs-3" key={i} fill={fill} x={wedgeStart} y={y}>
            | {r["piece"]}
          </text>
        );
      })}
    </g>
  );
}

function SummaryGroupViz({ colWidth, data, kind, offset, rowHeight }) {
  return (
    <g transform={`translate(0, ${offset})`}>
      {data.map((t, i) => (
        <SummaryBarcodeRow
          key={i}
          colWidth={colWidth}
          index={i}
          kind={kind}
          row={t}
          rowHeight={rowHeight}
        />
      ))}
    </g>
  );
}

function SummaryBarcodeViz({ concert, data, kind, level }) {
  const vizData = vizDataToRect(data, RowReference.SEGMENT, kind);
  const [width, height] = [1920, 1080];
  const headerHeight = 40;
  const rowHeight = (height - headerHeight) / (vizData.length + 2);
  const colWidth = width / vizData[0][level].length;
  const femaleRows = vizData.filter((d) => concert.female[d.token] != null);
  const maleRows = vizData.filter((d) => concert.male[d.token] != null);
  const maleOffset = headerHeight + rowHeight * (femaleRows.length + 3);
  return (
    <div className="flex-grow-1" data-cy="barcode-viz">
      <svg viewBox={`0 0 ${width} ${height}`}>
        <BarcodeHeader
          colWidth={colWidth}
          row={vizData[0]}
          rowHeight={headerHeight}
        />
        <SummaryGroupViz
          colWidth={colWidth}
          data={femaleRows}
          kind={kind}
          offset={headerHeight}
          rowHeight={rowHeight}
        />
        <text
          className="fs-5"
          textAnchor="end"
          x="1920"
          y={headerHeight + rowHeight * (femaleRows.length + 1.5)}>
          Frauen
        </text>
        <SummaryGroupViz
          colWidth={colWidth}
          data={maleRows}
          kind={kind}
          offset={maleOffset}
          rowHeight={rowHeight}
        />
        <text
          className="fs-5"
          textAnchor="end"
          x="1920"
          y={maleOffset + rowHeight * (maleRows.length + 1.5)}>
          Männer
        </text>
      </svg>
    </div>
  );
}

function SummaryBarcode({ concert }) {
  const kindValue = "rr";
  const levelValue = "segments";
  const { data, isLoading } = useGetScriptDataQuery(concert.concertDate);
  if (isLoading) return <Spinner />;
  return (
    <SummaryBarcodeViz
      concert={concert}
      data={data}
      kind={kindValue}
      level={levelValue}
    />
  );
}

function SummaryHeader({ concert }) {
  return <h3>{concert.settingId}</h3>;
}

function ConcertSummary({ concert }) {
  return (
    <div className="mt-5">
      <SummaryHeader concert={concert} />
      <SummaryBarcode concert={concert} />
    </div>
  );
}

function Summary() {
  return (
    <>
      <ConcertSummary concert={boulez1} />
      <div className="mt-5">&nbsp;</div>
      <ConcertSummary concert={boulez2} />
    </>
  );
}

export default Summary;
