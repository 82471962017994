import { useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useOutletContext
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useGetCurrentScriptQuery } from "../../services/pranaApi";
import EcrSound from "../ecr-sound";
import { incrementSeq } from "./script-slice";

function ShowError({ error }) {
  return error ? (
    <div>
      {error.status} {error.error}
    </div>
  ) : null;
}

function ShowLoading() {
  return null;
}

function ShowScript({ data, section }) {
  return data ? (
    <div className="d-flex flex-column flex-grow-1">{section}</div>
  ) : null;
}

function Script({ intro, section }) {
  const seq = useSelector((state) => state.script.seq);
  const { data, error, isLoading } = useGetCurrentScriptQuery(seq);
  return (
    <>
      <ShowError error={error} />
      <ShowLoading intro={intro.component} isLoading={isLoading} />
      <ShowScript data={data} section={section} />
    </>
  );
}

function tickState(props) {
  const {
    className,
    current,
    hasPlayed,
    navigate,
    sectionDurationS,
    sectionsLength,
    setClassName
  } = props;
  const fadeDurS = 5;
  const nextTickS = className === "fade-in" ? sectionDurationS : fadeDurS;
  const to = setTimeout(() => {
    if (className === "fade-in") {
      setClassName("fade-out");
    } else {
      let nextSection = current + 1;
      if (nextSection > sectionsLength - 1) nextSection = 0;
      if (!hasPlayed) nextSection = "lobby";
      navigate(`/${nextSection}`);
      setClassName("fade-in");
    }
  }, nextTickS * 1000);
  return to;
}

function Current() {
  const dispatch = useDispatch();
  const hasPlayed = useSelector((state) => state.sound.hasPlayed);
  const [className, setClassName] = useState("fade-in");
  const params = useParams();
  const sections = useOutletContext();
  const current = +(params.current ?? 0);
  const sectionDurationS = sections[current].durationS;
  const navigate = useNavigate();
  useEffect(() => {
    const tickStateProps = {
      className,
      current,
      hasPlayed,
      navigate,
      sectionDurationS,
      sectionsLength: sections.length,
      setClassName
    };
    const to = tickState(tickStateProps);
    return () => clearTimeout(to);
  }, [
    className,
    current,
    hasPlayed,
    navigate,
    sections.length,
    sectionDurationS
  ]);
  useEffect(() => {
    if (current === 0) dispatch(incrementSeq());
  }, [current, dispatch]);
  return (
    <div className={className}>
      {sections[current].component}
      <EcrSound current={current} />
    </div>
  );
}

function Wrapper({ sections }) {
  return <Script intro={sections[0]} section={<Outlet context={sections} />} />;
}

const RoutedComponents = { Wrapper, Current };

export default RoutedComponents;

export { Script };
