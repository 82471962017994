import { createSlice } from "@reduxjs/toolkit";

function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  //The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min) + min);
}

const scriptSlice = createSlice({
  name: "script",
  initialState: {
    seq: randomInt(0, 15)
  },
  reducers: {
    incrementSeq(state, _action) {
      state.seq = state.seq + 1;
    }
  }
});

export const { incrementSeq } = scriptSlice.actions;
export default scriptSlice.reducer;
