import Container from "react-bootstrap/Container";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams
} from "react-router-dom";

import Script from "./features/script";
import Barcode from "./features/barcode";
import Intro from "./pages/intro";
import Lobby from "./pages/lobby";
import Poster, { PosterExplanation, posterSearchParams } from "./pages/poster";
import Summary from "./pages/summary";

function InstallationWrapper() {
  const sections = [
    { component: <Intro />, durationS: 30 },
    { component: <Barcode level="pieces" />, durationS: 60 * 2.6 },
    { component: <Barcode level="movements" />, durationS: 60 * 3.5 },
    { component: <Barcode level="segments" />, durationS: 60 * 5.5 }
  ];
  return (
    <main className="h-100 no-cursor">
      <div className="h-100 mx-md-5">
        <div id="content" className="ptb-2 h-100 d-flex flex-column">
          <Container fluid className="h-100">
            <Script.Wrapper sections={sections} />
          </Container>
        </div>
      </div>
    </main>
  );
}

function PosterWrapper() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const params = posterSearchParams(searchParams);
  const mainClass = params ? "h-100 mx-md-3 poster" : "h-100 mx-md-5";
  const height = 842;
  const width = 1190.7;
  const style = { height: `${height}pt`, width: `${width}pt` };
  return (
    <main className={mainClass} style={style}>
      <div id="content" className="ptb-2 h-100 d-flex flex-column">
        <Container fluid className="h-100">
          <Poster />
        </Container>
      </div>
    </main>
  );
}

function PosterExplanationWrapper() {
  const mainClass = "h-100 mx-md-5";
  return (
    <main
      className={mainClass}
      style={{ backgroundColor: "white", color: "#000" }}>
      <div className="pt-1 mb-4 h-100 d-flex flex-column">
        <Container fluid className="h-100" style={{ fontSize: "32px" }}>
          <PosterExplanation />
        </Container>
      </div>
    </main>
  );
}

function LobbyWrapper() {
  const mainClass = "h-100 mx-md-5";
  return (
    <main className={mainClass}>
      <div className="pt-1 mb-4 h-100 d-flex flex-column">
        <Container fluid className="h-100">
          <Lobby />
        </Container>
      </div>
    </main>
  );
}

function SummaryWrapper() {
  const mainClass = "h-100 mx-md-5 poster";
  return (
    <main className={mainClass}>
      <div className="pt-1 mb-4 h-100 d-flex flex-column">
        <Container fluid className="h-100">
          <Summary />
        </Container>
      </div>
    </main>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/poster" element={<PosterWrapper />} />
        <Route path="/explain" element={<PosterExplanationWrapper />} />
        <Route path="/lobby" element={<LobbyWrapper />} />
        <Route path="/summary" element={<SummaryWrapper />} />
        <Route path="/" element={<InstallationWrapper />}>
          <Route index element={<Script.Current />} />
          <Route path=":current" element={<Script.Current />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
