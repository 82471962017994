import { configureStore } from "@reduxjs/toolkit";
import { soundReducer } from "../features/ecr-sound";
import { markdownReducer } from "../features/markdown";
import { scriptReducer } from "../features/script";
import { pranaApi } from "../services/pranaApi";

export const store = configureStore({
  reducer: {
    markdown: markdownReducer,
    [pranaApi.reducerPath]: pranaApi.reducer,
    script: scriptReducer,
    sound: soundReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pranaApi.middleware)
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
