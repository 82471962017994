import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Markdown from "../features/markdown";

function Home() {
  const spinner = <div></div>;
  return (
    <>
      <Row>
        <Col md={6}>
          <Markdown source="pages/home-de.md" spinner={spinner} />
        </Col>
        <Col md={6}>
          <Markdown source="pages/home-en.md" spinner={spinner} />
        </Col>
      </Row>
    </>
  );
}

export default Home;
