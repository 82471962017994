import { useSelector } from "react-redux";

import {
  useGetCurrentScriptQuery,
  useGetScriptDataQuery
} from "../services/pranaApi";

import { RowReference, vizDataToRect } from "../utils/viz";

function BarcodeAnimate({ animate, colWidth, dur, index, wedge }) {
  if (!animate) return null;
  const easeInOutKeySplines = "0.45 0 0.45 1 ; 0.45, 0 0.45 1";
  const easeInOutKeyTimes = "0;0.5;1";
  return (
    <>
      <animate
        attributeName="width"
        calcMode="spline"
        dur={dur}
        keySplines={easeInOutKeySplines}
        keyTimes={easeInOutKeyTimes}
        repeatCount="indefinite"
        values={`${wedge.width};${colWidth};${wedge.width}`}
      />
      <animate
        attributeName="x"
        calcMode="spline"
        dur={dur}
        keySplines={easeInOutKeySplines}
        keyTimes={easeInOutKeyTimes}
        repeatCount="indefinite"
        values={`${wedge.start};${colWidth * index};${wedge.start}`}
      />
    </>
  );
}

const pieceColorMap = {
  beethoven: "#1b9e77",
  dean: "#d95f02",
  brahms: "#7570b3"
};

function pieceFillColorColor(isHighlighted, piece) {
  if (!isHighlighted) return "#C0C0C0";
  const color = pieceColorMap[piece];
  return color ?? "#000";
}

const pieceBwMap = {
  beethoven: "#606060",
  dean: "#111",
  brahms: "#606060"
};

function pieceFillColorBW(isHighlighted, piece) {
  if (!isHighlighted) return "#CFCFCF";
  const color = pieceBwMap[piece];
  return color ?? "#000";
}

function segmentFillColor(isHighlighted, isBw, segment) {
  return isBw
    ? pieceFillColorBW(isHighlighted, segment["piece"])
    : pieceFillColorColor(isHighlighted, segment["piece"]);
}

function BarcodeRow({
  animate,
  colWidth,
  highlighted,
  index,
  isBw,
  kind,
  row,
  rowHeight
}) {
  const dur = `40s`;
  const tokenComponents = row.token.split("/");
  const isHighlighted =
    tokenComponents[tokenComponents.length - 1] === highlighted;
  return (
    <g transform={`translate(0, ${rowHeight * index})`}>
      {row["segments"].map((r, i) => {
        if (r[kind]["start"] == null) return null;

        const wedgeStart = colWidth * (i + r[kind]["start"]);
        const wedgeWidth = colWidth * r[kind]["width"];

        const fill = segmentFillColor(isHighlighted, isBw, r);

        return (
          <rect
            key={i}
            fill={fill}
            x={wedgeStart}
            y={0}
            width={wedgeWidth}
            height={rowHeight}>
            <BarcodeAnimate
              animate={animate}
              colWidth={colWidth}
              dur={dur}
              index={i}
              wedge={{ start: wedgeStart, width: wedgeWidth }}
            />
          </rect>
        );
      })}
    </g>
  );
}

function BarcodeHeader({ colWidth, highlighted, isBw, row, rowHeight }) {
  if (highlighted == null) return null;
  const y = rowHeight * 0.66;
  return (
    <g transform={`translate(0, 0)`}>
      {row["segments"].map((r, i) => {
        const wedgeStart = colWidth * i;
        const fill = segmentFillColor(true, isBw, r);
        if (r["segment"] !== 1 || r["movement"] !== 1) return null;
        return (
          <text className="fs-3" key={i} fill={fill} x={wedgeStart} y={y}>
            | {r["piece"]}
          </text>
        );
      })}
    </g>
  );
}

function BarcodeViz({
  animate,
  data,
  isBw,
  isLoading,
  highlighted,
  kind,
  level
}) {
  if (isLoading) return null;
  if (isBw == null) isBw = false;

  const vizData = vizDataToRect(data, RowReference.SEGMENT, kind);
  const [width, height] = [1920, 1080];
  const headerHeight = 40;
  const rowHeight = (height - headerHeight) / vizData.length;
  const colWidth = width / vizData[0][level].length;
  return (
    <div className="flex-grow-1" data-cy="barcode-viz">
      <svg viewBox={`0 0 ${width} ${height}`}>
        <BarcodeHeader
          colWidth={colWidth}
          highlighted={highlighted}
          isBw={isBw}
          row={vizData[0]}
          rowHeight={headerHeight}
        />
        <g transform={`translate(0, ${headerHeight})`}>
          {vizData.map((t, i) => (
            <BarcodeRow
              key={i}
              animate={animate}
              colWidth={colWidth}
              highlighted={highlighted}
              index={i}
              isBw={isBw}
              kind={kind}
              row={t}
              rowHeight={rowHeight}
            />
          ))}
        </g>
      </svg>
    </div>
  );
}

function Barcode({ animate, kind, level }) {
  const seq = useSelector((state) => state.script.seq);
  const animateValue = animate ?? true;
  const kindValue = kind ?? "rr";
  const levelValue = level ?? "segments";
  const { data: scriptData } = useGetCurrentScriptQuery(seq);
  const { data, isLoading } = useGetScriptDataQuery(scriptData.dataPath);
  return (
    <BarcodeViz
      animate={animateValue}
      data={data}
      highlighted={null}
      isLoading={isLoading}
      kind={kindValue}
      level={levelValue}
    />
  );
}

export default Barcode;

export { BarcodeViz };
