import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as _c from "lodash/collection";
import * as _a from "lodash/array";

// Define a service using a base URL and expected endpoints
export const pranaApi = createApi({
  reducerPath: "pranaApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/prana-server/api/" }),
  tagTypes: ["Overview"],
  endpoints: (builder) => ({
    getCurrentScript: builder.query({
      query: (seq) => `/script/current.json`
    }),
    getOverviewData: builder.query({
      query: () => ({
        providesTags: (_result, _error, id) => [{ type: "Overview", id }],
        url: "/overview/data.json"
      })
    }),
    getScriptData: builder.query({
      query: (dataPath) => ({
        url: `/data/${dataPath}/data.json`
      })
    }),
    triggerFetchData: builder.mutation({
      query: () => ({
        invalidatesTags: ["Overview"],
        url: "/trigger/fetchData"
      })
    })
  })
});

export const {
  useGetCurrentScriptQuery,
  useGetOverviewDataQuery,
  useGetScriptDataQuery,
  useTriggerFetchDataMutation
} = pranaApi;
