import Home from "./home";

function Intro() {
  return (
    <header className="mt-5">
      <h1 style={{ width: 360, fontSize: "xxx-large" }}>प्राण prāna</h1>
      <Home />
    </header>
  );
}

export default Intro;
